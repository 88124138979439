import { defineStore } from "pinia";
import { PriorityQueue } from "@/utils";

export enum AlertPriority {
    Info = 0,
    Warning,
    Error,
    AxiosError,
}

export interface Alert {
    contents: string;
    priority: AlertPriority;
    backgroundColor: string;
    buttonColor: string;
    timeout: number;
}

export type AlertModel = Partial<Alert>;

export type AlertsState = {
    alerts: PriorityQueue<Alert>;
};

export const useAlertsStore = defineStore("alerts", {
    state: (): AlertsState => ({
        alerts: new PriorityQueue(),
    }),
    actions: {
        enqueueAlert(alertModel: AlertModel) {
            const alert = {
                contents: alertModel.contents ?? "",
                priority: alertModel.priority ?? AlertPriority.Info,
                backgroundColor: alertModel.backgroundColor ?? "surface",
                buttonColor: alertModel.buttonColor ?? "primary",
                timeout: alertModel.timeout ?? 5000,
            };
            this.alerts.enqueue(alert, alert.priority);
        },

        dequeueAlert() {
            this.alerts.dequeue();
        },
    },
    getters: {
        getCurrentAlert: (state) => {
            if (state.alerts.isEmpty()) {
                return null;
            } else {
                return state.alerts.values[0]!.value;
            }
        },
    },
});
