import { authApi, type AuthUser } from "@/api/auth";
import { userApi } from "@/api/users";
import { OrganizationId, OrganizationPermission } from "@/api/organizations";
import { type ProjectId, ProjectPermission } from "@/api/projects";
import type { UserId } from "@/api/users";
import { defineStore } from "pinia";
import { useAboutStore } from "./about";
import { useAuditLogsStore } from "./auditLogs";
import { useEmailsStore } from "./emails";
import { useIconsStore } from "./icons";
import { useOrganizationsStore } from "./organizations";
import { useProjectsStore } from "./projects";
import { useSubscriptionsStore } from "./subscriptions";
import { useTeamsStore } from "./teams";
import { useTicketListsStore } from "./ticketLists";
import { useTicketsStore } from "./tickets";
import { useUsersStore } from "./users";
import { useAlertsStore } from "./alerts";
import * as Sentry from "@sentry/vue";

export type AuthState = {
    isAuthenticated: boolean;
    route: string;
    user: AuthUser | null;
};

export const useAuthStore = defineStore("auth", {
    state: (): AuthState => ({
        isAuthenticated: false,
        route: "/",
        user: null,
    }),
    getters: {
        hasPermission(state) {
            return (project: ProjectId, perm: ProjectPermission) =>
                state.user?.isAdmin ||
                !!state.user?.projectPermissions
                    .get(project)
                    ?.some((p) => p === perm || p === ProjectPermission.ProjectAdministrator);
        },
        hasOrganizationPermission(state) {
            return (organization: OrganizationId, perm: OrganizationPermission) =>
                state.user?.isAdmin ||
                !!state.user?.organizationPermissions
                    .get(organization)
                    ?.some(
                        (p) => p === perm || p === OrganizationPermission.OrganizationAdministrator
                    );
        },
    },
    actions: {
        async authenticated() {
            const auth = await authApi.authenticated();
            if (auth !== null) {
                this.$patch({ user: auth, isAuthenticated: true });
                Sentry.setUser({ email: auth.email });
            }

            return auth !== null;
        },
        async login(userName: string, password: string, rememberMe: boolean) {
            const subStore = useSubscriptionsStore();
            const auth = await authApi.login(userName, password, rememberMe);
            if (auth) {
                this.$patch({ user: auth, isAuthenticated: true });
                await subStore.createConnection();
                Sentry.setUser({ email: auth.email });
                return true;
            } else {
                return false;
            }
        },
        async confirm(userId: UserId, token: string, userName: string, password: string) {
            const auth = await authApi.confirm(userId, token, userName, password);
            this.$patch({ user: auth, isAuthenticated: true });
        },
        async logout() {
            await authApi.logout();
            Sentry.setUser(null);

            this.$state = {
                isAuthenticated: false,
                route: "/",
                user: null,
            };

            const aboutStore = useAboutStore();
            const alertsStore = useAlertsStore();
            const auditLogsStore = useAuditLogsStore();
            const emailsStore = useEmailsStore();
            const iconsStore = useIconsStore();
            const organizationsStore = useOrganizationsStore();
            const projectsStore = useProjectsStore();
            const subscriptionsStore = useSubscriptionsStore();
            const teamsStore = useTeamsStore();
            const ticketListsStore = useTicketListsStore();
            const ticketsStore = useTicketsStore();
            const usersStore = useUsersStore();

            aboutStore.$reset();
            alertsStore.$reset();
            auditLogsStore.$reset();
            emailsStore.$reset();
            iconsStore.$reset();
            organizationsStore.$reset();
            projectsStore.$reset();
            subscriptionsStore.$reset();
            teamsStore.$reset();
            ticketListsStore.$reset();
            ticketsStore.$reset();
            usersStore.$reset();
        },
        async uploadAvatar(file: File) {
            if (!this.user) {
                throw new Error("Cannot set avatar without a user.");
            }

            const userStore = useUsersStore();
            const avatar = await userApi.uploadUserAvatar(file);

            this.user.avatarId = avatar.id;

            const user = userStore.get(this.user.id);
            if (user) {
                user.avatarId = avatar.id;
            }

            if (userStore.avatars.size > 0) {
                userStore.avatars.set(avatar.id, avatar);
            } else {
                await userStore.loadAvatars();
            }
        },
        async deleteAvatar() {
            if (!this.user?.avatarId) {
                throw new Error("Cannot set avatar without a user.");
            }

            const userStore = useUsersStore();
            await userApi.deleteUserAvatar();

            const oldAvatar = this.user.avatarId;
            this.user.avatarId = null;

            const user = userStore.get(this.user.id);
            if (user) {
                user.avatarId = null;
            }
            userStore.avatars.delete(oldAvatar);
        },
    },
});
